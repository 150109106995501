import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import ReportListToolbar from "../components/ReportListToolbar";
import orderService from "../services/orderService";
import AlertMessage from "../../../components/alert/alert";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  CardContent,
  TableContainer,
  TablePagination,
  Container,
} from "@mui/material";
import OrdersListHead from "../components/OrdersListHead";
import SearchNotFound from "../../../components/SearchNotFound";
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import PasswordModal from "../components/PasswordModal";

const TABLE_HEAD = [
  { id: "date", label: "Data", alignRight: false },
  { id: "id_Order", label: "O.S", alignRight: false },
  { id: "description", label: "Descrição", alignRight: false },
  { id: "amount", label: "Qtde (Pares)", alignRight: false },
  { id: "value", label: "Valor", alignRight: false },
];

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array: any, comparator: any, query: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    if (query.includes("-")) {
      if (query.includes(" ")) {
        const stringDates = query.split(" ");
        const dates = [new Date(stringDates[0]), new Date(stringDates[1])];
        dates[0].setHours(0);
        dates[1].setHours(0);

        return filter(array, (_report) => {
          const dateData = _report.date.split("/");
          const arrDate = new Date(
            `${dateData[2]}-${dateData[1]}-${dateData[0]}`
          );
          arrDate.setHours(0);

          return arrDate <= dates[1] && arrDate >= dates[0];
        });
      } else {
        let greaterThan: boolean = false;
        if (query.includes("&")) {
          greaterThan = true;
          query.replace("&", "");
        }

        const date = new Date(query);
        date.setHours(0);

        return filter(array, (_report) => {
          const dateData = _report.date.split("/");
          const arrDate = new Date(
            `${dateData[2]}-${dateData[1]}-${dateData[0]}`
          );
          arrDate.setHours(0);

          // if (!greaterThan) arrDate.setDate(arrDate.getDate() + 1);
          return greaterThan ? date > arrDate : date <= arrDate;
        });
      }
    } else {
      return filter(
        array,
        (_report) =>
          _report.id_Order
            ?.toLocaleString("pt-br", {
              minimumIntegerDigits: 5,
              useGrouping: false,
            })
            .toString()
            .indexOf(query) !== -1
      );
    }
  }
  return stabilizedThis.map((el: any) => el[0]);
}

export default function ReportPrePaid() {
  const parse: any = localStorage.getItem("user");
  const user = JSON.parse(parse);

  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [order, setOrder] = useState("asc");
  const [password, setPassword] = useState("");
  const [showError, setError] = useState(false);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [passwordModal, setPasswordModal] = useState(false);

  useEffect(() => {
    setOrder("asc");
    setOrderBy("name");
  }, []);

  useEffect(() => {
    let greaterThan: boolean = false;
    let dates: any = [];
    let date;

    if (password.length) {
      if (filterDate) {
        if (filterDate.includes(" ")) {
          const stringDates = filterDate.split(" ");
          const date1 = stringDates[0].split("-");
          const date2 = stringDates[1].split("-");
          dates = [
            `${date1[2]}/${date1[1]}/${date1[0]}`,
            `${date2[2]}/${date2[1]}/${date2[0]}`,
          ];
        } else {
          if (filterDate.includes("&")) {
            greaterThan = true;
            filterDate.replace("&", "");
          }
          const stringDate = filterDate.split("-");

          date = `${stringDate[2]}/${stringDate[1]}/${stringDate[0]}`;
        }
      }
      if (user.prepaid) {
        if (TABLE_HEAD.length === 5)
          TABLE_HEAD.push({ id: "balance", label: "Saldo", alignRight: false });
      } else {
        if (TABLE_HEAD.length === 6) TABLE_HEAD.pop();
      }

      orderService
        .getReportPrePaid(
          user.idCustomer?.toString(),
          user.prepaid,
          password,
          dates?.length
            ? dates[0]
            : !greaterThan
            ? date || undefined
            : undefined,
          dates?.length ? dates[1] : greaterThan ? date || undefined : undefined
        )
        .then((data: any) => {
          setData(data.data);
          setPasswordModal(false);
        })
        .catch((error: any) => {
          setError(true);
          setPassword("");
          console.error(error);
        });
    } else setPasswordModal(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate, password]);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
  }, [showError]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers: any = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <Page title="Relatório | Optools">
        <Container>
          <PasswordModal
            open={passwordModal}
            handler={() => setPasswordModal(false)}
            changePassword={(pass: string) => setPassword(pass)}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4" gutterBottom>
              Relatório
            </Typography>
          </Stack>
          <Stack direction={["column", "row"]} justifyContent="space-between">
            <ReportListToolbar
              filterName={filterName}
              onFilterName={(value: any) => setFilterName(value)}
              onFilterDate={(value: any) => setFilterDate(value)}
            />
          </Stack>
          <Card>
            <CardContent sx={{ paddingTop: [0, "auto"] }}>
              <Scrollbar>
                <TableContainer>
                  <Table>
                    <OrdersListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {data.length > 1 ? (
                        filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row: any, index: number) => {
                            const {
                              date,
                              code_OrderCode,
                              description,
                              amount,
                              value,
                              balance,
                            } = row;

                            if (date)
                              return (
                                <TableRow
                                  hover
                                  key={index}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={false}
                                  aria-checked={false}
                                >
                                  <TableCell component="th" scope="row">
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      sx={{
                                        color: "#004A78",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {date}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      sx={{
                                        color: "#004A78",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {code_OrderCode}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      sx={{
                                        color: "#004A78",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {description}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      sx={{
                                        color: "#004A78",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {amount}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      sx={{
                                        color: "#004A78",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {value}
                                    </Typography>
                                  </TableCell>
                                  {user.prepaid ? (
                                    <TableCell align="left">
                                      <Typography
                                        variant="subtitle2"
                                        noWrap
                                        sx={{
                                          color: "#004A78",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {balance}
                                      </Typography>
                                    </TableCell>
                                  ) : undefined}
                                </TableRow>
                              );
                            else return undefined;
                          })
                      ) : (
                        <>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <Stack
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Icon
                                  icon={inboxOutline}
                                  width="56px"
                                  color="#909090"
                                />
                                <Typography
                                  mt={1}
                                  variant="body1"
                                  noWrap
                                  sx={{ color: "#909090" }}
                                >
                                  Nenhum item encontrado...
                                </Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                      <TableRow sx={{ backgroundColor: "#004A78" }}>
                        <TableCell colSpan={6} sx={{ pr: 10 }}>
                          <Stack
                            flexDirection="row"
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="body1"
                              noWrap
                              sx={{ color: "#FFF" }}
                            >
                              {user.prepaid ? "Saldo Atual" : "Somatório"}
                            </Typography>
                            <Typography
                              variant="body1"
                              noWrap
                              sx={{ color: "#FFF", fontWeight: "bold" }}
                            >
                              {user.prepaid
                                ? data[data.length - 1]?.currentBalance ||
                                  "0.00"
                                : data[data.length - 1]?.totalValue || "0.00"}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    {isUserNotFound && data.length ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : undefined}
                  </Table>
                </TableContainer>

                <TablePagination
                  page={page}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 20, 50]}
                  labelRowsPerPage="Linhas por página:"
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={(rowsInfo) => {
                    const totalPages = Math.ceil(
                      rowsInfo.count / rowsPerPage
                    ).toString();
                    return (
                      <>
                        {`Página: ${page + 1}${`/${
                          totalPages === "0" ? "1" : totalPages
                        }`}`}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {`Total: ${rowsInfo.count}`}
                      </>
                    );
                  }}
                />
              </Scrollbar>
            </CardContent>
          </Card>
        </Container>
      </Page>
      {showError && <AlertMessage open={true} message="Senha incorreta!" />}
    </>
  );
}
